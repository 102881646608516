import request from '@/utils/request'
let resquest = "/api/"

//查询-部门客户指标表
export function getPageDempCustomer(data) {
    return request({
        url: `${resquest}api/report/profession/getPageDempCustomer`,
        method: 'post',
        data
    })
}