var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    model: _vm.queryForm,
                    "label-width": "100px"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cont-form" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "jobNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                placeholder: "运单号"
                              },
                              model: {
                                value: _vm.queryForm.jobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "jobNo", $$v)
                                },
                                expression: "queryForm.jobNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜号", prop: "cabinetNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cabinetNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "cabinetNo", $$v)
                                },
                                expression: "queryForm.cabinetNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "是否混箱", prop: "isMixed" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.isMixed,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "isMixed", $$v)
                                  },
                                  expression: "queryForm.isMixed"
                                }
                              },
                              _vm._l(_vm.yseOrNo, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "报关方式", prop: "customsType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.customsType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "customsType", $$v)
                                  },
                                  expression: "queryForm.customsType"
                                }
                              },
                              _vm._l(_vm.customsType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "清关方式", prop: "taxType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.taxType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "taxType", $$v)
                                  },
                                  expression: "queryForm.taxType"
                                }
                              },
                              _vm._l(_vm.taxType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "success",
                    loading: _vm.loadingExPort
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 底单导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "info",
                    loading: _vm.loadingExPortPri
                  },
                  on: { click: _vm.exPortPri }
                },
                [_vm._v(" 报关资料导出 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: {
            border: "",
            data: _vm.list,
            "row-style": { height: "30px" },
            "cell-style": { padding: "2px" },
            "element-loading-text": "拼命加载中",
            height: _vm.height,
            "row-class-name": _vm.rowStyle
          },
          on: {
            "selection-change": _vm.setSelectRows,
            "row-click": _vm.handleRowClick
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              type: "selection",
              width: "55"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cabinetNo",
              label: "柜号",
              "class-name": "myCell",
              width: "140",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.cabinetNo) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "jobNo",
              label: "运单号",
              "class-name": "myCell",
              width: "140",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.jobNo) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cusProductCnName",
              "class-name": "myCell",
              label: "客户中文品名",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.cusProductCnName) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "cusProductEnName",
              "class-name": "myCell",
              label: "客户英文品名",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.cusProductEnName) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "hsCode",
              label: "海关编码",
              "class-name": "myCell",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.hsCode) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "reportCnName",
              label: "申报中文品名",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.reportCnName) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "reportEnName",
              label: "申报英文品名",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.reportEnName) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "hsCodeCn",
              label: "中国海关编码",
              "class-name": "myCell",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.hsCodeCn) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "reportFactor",
              label: "中国申报要素",
              "class-name": "myCell",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.reportFactor) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "materialCn",
              label: "中文材质",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.materialCn) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "materialEn",
              label: "英文材质",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.materialEn) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "childJobNo",
              label: "子单号",
              "show-overflow-tooltip": "",
              width: "240"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "brand",
              label: "品牌",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.brand) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "brandType",
              label: "品牌类型",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.brandType) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "specs",
              label: "规格",
              width: "140",
              "class-name": "myCell"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.specs) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "uses",
              label: "用途",
              "class-name": "myCell",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.uses) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "goodsType",
              label: "货物类型",
              "class-name": "myCell",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.goodsType) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "unit",
              label: "单位",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.unit) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "pcs",
              label: "件数",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "netWeight",
              label: "净重",
              "class-name": "myCell",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.netWeight) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "grossWeight",
              label: "毛重",
              "class-name": "myCell",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "receiveNetWeight",
              label: "收货总净重",
              "class-name": "myCell",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "receiveGrossWeight",
              label: "收货总实重",
              "class-name": "myCell",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "vol",
              label: "体积",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "qty",
              label: "数量",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.qty) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "purchasePrices",
              label: "单价",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.purchasePrices) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "totalPrice",
              label: "总价",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.totalPrice) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "length",
              label: "长",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "width",
              label: "宽",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "height",
              label: "高",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "transactionUrl",
              label: "链接",
              "class-name": "myCell",
              width: "140",
              fixed: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.transactionUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.transactionUrl))]
                        )
                      ]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customsType",
              label: "报关方式",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.customsType) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "taxType",
              label: "清关方式",
              "class-name": "myCell",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.taxType) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createBy",
              label: "创建人",
              "class-name": "myCell",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.createBy) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "createTime",
              label: "创建时间",
              "class-name": "myCell",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [_vm._v(" " + _vm._s(item.createTime) + " ")]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "isMixed",
              label: "是否混装",
              "class-name": "myCell",
              width: "80",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.list, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "myCellDiv",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(item.isMixed))
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  })
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 全部合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.fomr.totalGrossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.fomr.totalNetWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.fomr.totalReceiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.fomr.totalReceiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.fomr.totalPcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.fomr.totalQty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.fomr.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.fomr.totalVol))])
      ]),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 选中数据合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.selectForm.grossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.selectForm.netWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.selectForm.receiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.selectForm.receiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.selectForm.pcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.selectForm.qty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.selectForm.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.selectForm.vol))])
      ]),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
          "page-sizes": [100, 200, 300, 500, 1000]
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }