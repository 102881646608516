var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cont-form" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "jobNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "运单号",
                                rows: 3,
                                type: "textarea"
                              },
                              model: {
                                value: _vm.queryForm.jobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "jobNo", $$v)
                                },
                                expression: "queryForm.jobNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "是否混箱", prop: "isMixed" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.isMixed,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "isMixed", $$v)
                                  },
                                  expression: "queryForm.isMixed"
                                }
                              },
                              _vm._l(_vm.yseOrNo, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "报关方式", prop: "customsType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.customsType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "customsType", $$v)
                                  },
                                  expression: "queryForm.customsType"
                                }
                              },
                              _vm._l(_vm.customsType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "清关方式", prop: "taxType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.taxType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "taxType", $$v)
                                  },
                                  expression: "queryForm.taxType"
                                }
                              },
                              _vm._l(_vm.taxType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜号", prop: "cabinetNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cabinetNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "cabinetNo", $$v)
                                },
                                expression: "queryForm.cabinetNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "success"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 底单导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPortPri,
                    size: "mini",
                    type: "info"
                  },
                  on: { click: _vm.exPortPri }
                },
                [_vm._v(" 报关资料导出 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            border: "",
            data: _vm.list,
            height: "300",
            "highlight-current-row": "",
            "export-config": {},
            "highlight-hover-row": "",
            loading: _vm.listLoading,
            resizable: "",
            "checkbox-config": { reserve: true },
            "show-header-overflow": "",
            "scroll-x": {
              gt: 10,
              oSize: 10
            },
            "show-overflow": "",
            "span-method": _vm.objectSpanMethod
          },
          on: {
            "checkbox-all": _vm.checkboxAll,
            "sort-change": _vm.sortChange
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("vxe-column", {
            attrs: {
              align: "center",
              fixed: "left",
              type: "checkbox",
              width: "55"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "cabinetNo",
              fixed: "left",
              title: "柜号",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "jobNo",
              fixed: "left",
              title: "运单号",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "cusProductCnName",
              title: "客户中文品名",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "cusProductEnName",
              title: "客户英文品名",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "hsCode",
              title: "海关编码",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "reportCnName",
              title: "申报中文品名",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "reportEnName",
              title: "申报英文品名",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "hsCodeCn",
              title: "中国海关编码",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "reportFactor",
              title: "中国申报要素",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "materialCn",
              title: "中文材质",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "materialEn",
              title: "英文材质",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "childJobNo",
              "show-overflow-tooltip": "",
              title: "子单号",
              width: "240"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "brand",
              title: "品牌",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "brandType",
              title: "品牌类型",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "specs",
              title: "规格",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "uses",
              title: "用途",
              width: "140"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "goodsType",
              title: "货物类型",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "unit",
              title: "单位",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "pcs",
              "show-overflow-tooltip": "",
              title: "件数",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "netWeight",
              title: "净重",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "grossWeight",
              title: "毛重",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "receiveNetWeight",
              title: "收货总净重",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "receiveGrossWeight",
              title: "收货总实重",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "vol",
              "show-overflow-tooltip": "",
              title: "体积",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: { align: "center", field: "qty", title: "数量", width: "80" }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "purchasePrices",
              title: "单价",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "totalPrice",
              title: "总价",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "length",
              "show-overflow-tooltip": "",
              title: "长",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "width",
              "show-overflow-tooltip": "",
              title: "宽",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "height",
              "show-overflow-tooltip": "",
              title: "高",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "customsType",
              title: "报关方式",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "taxType",
              fixed: "right",
              title: "清关方式",
              width: "80"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "createBy",
              title: "创建人",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "createTime",
              title: "创建时间",
              width: "160"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "isMixed",
              fixed: "right",
              title: "是否混装",
              width: "80"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 全部合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.fomr.totalGrossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.fomr.totalNetWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.fomr.totalReceiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.fomr.totalReceiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.fomr.totalPcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.fomr.totalQty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.fomr.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.fomr.totalVol))])
      ]),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 选中数据合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.selectForm.grossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.selectForm.netWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.selectForm.receiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.selectForm.receiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.selectForm.pcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.selectForm.qty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.selectForm.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.selectForm.vol))])
      ]),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [100, 200, 300, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }