import request from '@/utils/request'
let resquest = "/api/"

//客户
export function costomerGetValidList(data) {
    return request({
        url: `${resquest}base/costomer/getValidList`,
        method: 'post',
        data
    })
}

//业务报表-询价总览表
export function businessGetInquiryOverviewList(data) {
    return request({
        url: `${resquest}report/business/getInquiryOverviewList`,
        method: 'post',
        data
    })
}

//业务报表-客户询价总览表
export function businessGetCustomerInquiryList(data) {
    return request({
        url: `${resquest}report/business/getCustomerInquiryList`,
        method: 'post',
        data
    })
}
