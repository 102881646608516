import request from '@/utils/request'
let resquest = "/api/"

//业务员提成数据报表查询
export function salesmanCommissionData(data) {
    return request({
        url: `${resquest}api/report/salesmanCommissionData/page`,
        method: 'post',
        data
    })
}

//提取/更新上月计提明细
export function refreshLastMonth(params) {
    return request({
        url: `${resquest}api/report/salesmanCommissionData/refreshLastMonth`,
        method: 'get',
        params
    })
}

export function monitorTask(params) {
    return request({
        url: `${resquest}api/report/salesmanCommissionData/monitorTask`,
        method: 'get',
        params
    })
}