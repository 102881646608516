<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="100px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item prop="jobNo">
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="是否混箱"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="报关方式"
                prop="customsType"
              >
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="success"
          @click="exPort"
        >
          底单导出
        </el-button>
        <el-button
          :loading="loadingExPortPri"
          size="mini"
          type="info"
          @click="exPortPri"
        >
          报关资料导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <vxe-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :checkbox-config="{ showHeader: 'true', highlight: 'true' }"
      :data="tableData"
      :height="height"
      :row-config="{ isHover: true, isCurrent: true }"
      :row-style="rowStyle"
      :scroll-y="{ enabled: true }"
      show-overflow
      @checkbox-all="selectAllEvent"
      @checkbox-change="selectAllEvent"
    >
      <vxe-column align="center" fixed="left" type="checkbox" width="55" />
      <vxe-column
        align="center"
        field="cabinetNo"
        fixed="left"
        title="柜号"
        width="140"
      />
      <vxe-column
        align="center"
        field="jobNo"
        fixed="left"
        title="运单号"
        width="140"
      />
      <vxe-column
        align="center"
        field="cusProductCnName"
        title="客户中文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="cusProductEnName"
        title="客户英文品名"
        width="140"
      />
      <vxe-column align="center" field="hsCode" title="海关编码" width="120" />
      <vxe-column
        align="center"
        field="reportCnName"
        title="申报中文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="reportEnName"
        title="申报英文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="hsCodeCn"
        title="中国海关编码"
        width="120"
      />
      <vxe-column
        align="center"
        field="reportFactor"
        title="中国申报要素"
        width="120"
      />
      <vxe-column
        align="center"
        field="materialCn"
        title="中文材质"
        width="140"
      />
      <vxe-column
        align="center"
        field="materialEn"
        title="英文材质"
        width="140"
      />

      <vxe-column
        align="center"
        field="childJobNo"
        show-overflow-tooltip
        title="子单号"
        width="240"
      />
      <vxe-column align="center" field="brand" title="品牌" width="140" />
      <vxe-column
        align="center"
        field="brandType"
        title="品牌类型"
        width="140"
      />
      <vxe-column align="center" field="specs" title="规格" width="140" />
      <vxe-column align="center" field="uses" title="用途" width="140" />
      <vxe-column
        align="center"
        field="goodsType"
        title="货物类型"
        width="120"
      />
      <vxe-column align="center" field="unit" title="单位" width="80" />
      <vxe-column
        align="center"
        field="pcs"
        show-overflow-tooltip
        title="件数"
        width="80"
      />
      <vxe-column align="center" field="netWeight" title="净重" width="100" />
      <vxe-column align="center" field="grossWeight" title="毛重" width="100">
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.grossWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="receiveNetWeight"
        title="收货总净重"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveNetWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="receiveGrossWeight"
        title="收货总实重"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveGrossWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="vol"
        show-overflow-tooltip
        title="体积"
        width="80"
      />

      <vxe-column align="center" field="qty" title="数量" width="80" />
      <vxe-column
        align="center"
        field="purchasePrices"
        title="单价"
        width="80"
      />
      <vxe-column align="center" field="totalPrice" title="总价" width="80" />
      <vxe-column
        align="center"
        field="length"
        show-overflow-tooltip
        title="长"
        width="80"
      />
      <vxe-column
        align="center"
        field="width"
        show-overflow-tooltip
        title="宽"
        width="80"
      />
      <vxe-column
        align="center"
        field="height"
        show-overflow-tooltip
        title="高"
        width="80"
      />
      <vxe-column
        align="center"
        field="transactionUrl"
        fixed="right"
        show-overflow-tooltip
        title="链接"
        width="140"
      >
        <template #default="{ row }">
          <a :href="row.transactionUrl" target="_blank">
            {{ row.transactionUrl }}
          </a>
        </template>
      </vxe-column>

      <vxe-column
        align="center"
        field="customsType"
        title="报关方式"
        width="80"
      />
      <vxe-column
        align="center"
        field="taxType"
        fixed="right"
        title="清关方式"
        width="80"
      />
      <vxe-column align="center" field="createBy" title="创建人" width="100" />
      <vxe-column
        align="center"
        field="createTime"
        title="创建时间"
        width="160"
      />
      <vxe-column
        align="center"
        field="isMixed"
        fixed="right"
        title="是否混装"
        width="80"
      >
        <template #default="{ row }">
          <el-tag type="success">{{ row.isMixed }}</el-tag>
        </template>
      </vxe-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </vxe-table>
    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight | toFixedNum }}</span>
      <span>订单净重：{{ fomr.totalNetWeight | toFixedNum }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight | toFixedNum }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight | toFixedNum }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice | toFixedNum }}</span>
      <span>体积：{{ fomr.totalVol | toFixedNum }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qty }}</span>
      <span>金额：{{ selectForm.totalPrice }}</span>
      <span>体积：{{ selectForm.vol }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000, 2000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Preview ref="preview" />
    <!-- <el-dialog
      title="上传Excel"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="close"
    >
      <el-upload
        class="upload-demo"
        drag
        action="#"
        ref="upload"
        v-loading="loadingDemo"
        :before-upload="beforeAvatarUpload"
        :on-change="handleChangeOne"
        :on-remove="handleRemoveOne"
        :http-request="handleAvatarSuccessImg"
        :file-list="fileListOne"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload>
    </el-dialog> -->
    <Sync ref="sync" />
  </div>
</template>

<script>
  let color_type = ''
  let color_lastIdList = []
  import {
    exportDraftCopy,
    exportProfile,
    pageDraftCopyThree,
  } from '@/api/cmsApi'
  // import {
  //   pageClearanceList,
  //   exportClearanceList,
  //   deleteClearanceList,
  //   importClearanceList,
  //   createFileClearanceList,
  //   confirmClearanceList,
  //   cancelClearanceList,
  //   updateClearanceList,
  //   updateDraftClearanceList,
  // } from '@/api/cmsApi'
  import Preview from '@/vab/components/Preview'
  import Sync from './components/sync'
  export default {
    name: 'ClearanceList',
    components: {
      Preview,
      Sync,
    },
    filters: {
      toFixedNum: function (value) {
        return value.toFixed(2)
      },
    },
    data() {
      return {
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        tableData: [],
        fold: true,
        loadingExPort: false,
        loadingExPortPri: false,
        listLoading: false,
        dialogFormVisible: false,
        loadingDemo: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        importType: '',
        fileListOne: [],
        yseOrNo: ['是', '否'],
        file: [],
        height: this.$baseTableHeight(3) + 50,
        queryForm: {
          cabinetNo: '',
          isMixed: '',
          customsType: '',
          taxType: '',
          jobNo: '',
          pageNum: 1,
          pageSize: 100,
          idList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: '0.00',
          receiveGrossWeight: '0.00',
          grossWeight: '0.00',
          netWeight: '0.00',
          pcs: 0,
          qty: '',
          totalPrice: '0.00',
          vol: '0.00',
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
        colorRow: 1,
      }
    },
    created() {
      // setTimeout(() => {
      //   // 模拟数据
      //   const mockList = []
      //   for (let index = 0; index < 1000; index++) {
      //     mockList.push({
      //       id: index,
      //       name: 'Test' + index,
      //       role: 'Developer',
      //       sex: '男',
      //     })
      //   }
      //   this.tableData = mockList
      // }, 100)
      // console.log(this.tableData);
      this.queryList()
    },
    methods: {
      selectAllEvent({ records }) {
        console.log(2323)
        console.log(records)
        this.queryForm.idList = records.map((item, index, array) => item.id)
        this.selectForm.grossWeight = this.countTotal(records, 'grossWeight')
        this.selectForm.netWeight = this.countTotal(records, 'netWeight')
        this.selectForm.pcs = this.countTotalTwo(records, 'pcs')
        this.selectForm.qty = this.countTotalTwo(records, 'qty')
        this.selectForm.totalPrice = this.countTotal(records, 'totalPrice')
        this.selectForm.vol = this.countTotal(records, 'vol')
        this.selectForm.receiveNetWeight = this.countTotal(
          records,
          'receiveNetWeight'
        )
        this.selectForm.receiveGrossWeight = this.countTotal(
          records,
          'receiveGrossWeight'
        )
      },
      //选中计算
      countTotal(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total.toFixed(2)
      },
      countTotalTwo(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total
      },
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await exportDraftCopy(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async exPortPri() {
        try {
          this.loadingExPortPri = true
          const res = await exportProfile(this.queryForm)
          this.jump(res.data)
          this.loadingExPortPri = false
        } catch (error) {
          this.loadingExPortPri = false
          this.$message.error('导出失败!')
        }
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      rowStyle({ row, rowIndex }) {
        if (!row.hasGroup) {
          return
        }

        let color1 = '#f5f7fa'
        let color2 = '#eaf4fe'

        let color3 = 'red'
        let color4 = 'blue'

        let id = row.id
        if (!color_type) {
          color_type = 1
          color_lastIdList = row.idList
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return {
              // color: color3
              backgroundColor: color1,
              fontWeight: 'blod',
            }
          } else {
            return {
              // fontWeight:'blod',
              // color: color4,
              // textDecoration: 'line-through',
              backgroundColor: color2,
            }
          }
        } else {
          color_lastIdList = row.idList
          if (color_type === 1) {
            color_type = 2
          } else {
            color_type = 1
          }
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return {
              // color: color3,
              // textDecoration: 'line-through',
              backgroundColor: color1,
            }
          } else {
            return {
              // color: color4,
              // textDecoration: 'line-through',
              backgroundColor: color2,
            }
          }
        }
      },
      handleRowClick({ row, column }) {
        console.log(row)
        console.log(column)
        if (row && row.title == '操作') {
          return
        }
        this.$refs.tableSort.toggleCheckboxRow(row)
        // this.selectAllEvent()
        // console.log([row.row]);
        // this.selectAllEvent([row.row])
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await pageDraftCopyThree(this.queryForm)
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol
        this.total = res.data.totalSize
        this.tableData = res.data.pageList
        this.listLoading = false
      },

      handleFold() {
        this.fold = !this.fold
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      handleChangeOne(file, fileList) {
        if (fileList.length > 0) {
          this.fileListOne = [fileList[fileList.length - 1]]
        }
      },
      handleRemoveOne(file, fileList) {
        this.queryForm.appFile = null
        for (let i = 0; i < this.fileListOne.length; i++) {
          if (this.fileListOne[i].uid == file.uid) {
            this.fileListOne.splice(i, 1)
            break
          }
        }
      },
      handleAvatarSuccessImg(params) {
        this.file.push(params.file)
        this.bt()
      },
      async bt() {
        this.loadingDemo = true
        for (let i = 0; i < this.file.length; i++) {
          this.queryForm.appFile = this.file[i]
        }
        let form = new FormData()
        form.append('file', this.queryForm.appFile)
        try {
          if (this.importType == 1) {
            const res = await importBasicFileForInsert(form)
          } else {
            const res = await importBasicFileForUpdate(form)
          }
          this.clone()
        } catch (error) {
          this.clone()
        }
      },
      clone() {
        this.loadingDemo = false
        this.dialogFormVisible = false
        this.close()
        this.file = []
        this.$refs.upload.clearFiles()
        this.queryList()
      },
      //弹出框隐藏回调
      close() {
        this.$refs.upload.clearFiles()
        this.importType = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  ::v-deep .left-panel {
    margin: 0px !important;
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .cont {
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
  }
  ::v-deep {
    .el-table {
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      .el-table__body-wrapper::-webkit-scrollbar-thnmb {
        background-color: #ccc !important;
        border-radius: 5px;
      }
      // 此段可写可不写 用处：可使固定列的高度不盖住滚动条
      // /deep/.el-table__fiexd,
      // .el-table__fixed-right{
      //  height:calc(100% - 12px)!important;
      //}
      // 关键代码，解决错位问题 思路就是表格固定列显示滚动条后把表格顶上来
      .el-table-fixed .el-table__body {
        padding-bottom: 12px;
      }
    }

    .vab-query-form[data-v-64063760] .top-panel {
      margin: 0px;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .left-panel {
      margin: 0px !important;
    }
    .left-panel {
      margin: 0px !important;
    }
    .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }
    .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }
    .el-dialog__body {
      text-align: center;
    }
    .rowStyle {
      background-color: #e8f7fd !important;
      color: #1890ff;
    }
  }
  // .my-form-item {
  // padding-left: 10px!important;
  // }
  .cont-form {
    display: flex;
  }
</style>
